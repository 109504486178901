.form_holder {
  position: relative;
  min-height: calc(
    100vh - #{$headerHeight}
  );
  display: flex;
  align-items: center;

  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/form_bg.jpg");
  background-size: cover;

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.4);
  }

  &__inner {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    color: #fff;
  }

  &__title {
    position: relative;
    margin-bottom: 2.5%;
    font-weight: 500;
    font-size: 55px;
    line-height: 1.2;
    text-shadow: -5px 0 8px rgba(#000, 0.5);

    &:after {
      content: '';
      position: absolute;
      top: 65px;
      right: -12px;
      display: block;
      width: 255px;
      height: 65px;
      background-image: url("../images/bg-arrow.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    @include maxMD {
      font-size: 34px;
    }

    @include onlyMD {
      &:after {
        top: 47px;
        width: 195px;
        height: 50px;
      }
    }

    @include maxSM {
      &:after {
        display: none;
      }
    }
  }

  .btn {
    max-width: 315px;
    width: 100%;
  }

  @include maxSM {
    min-height: calc(
      100vh - #{$headerHeightMobile}
    );
    .container, [class*="col"] {
      padding-left: 0;
      padding-right: 0;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
