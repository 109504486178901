.loading {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../images/loader.gif") no-repeat 50% 60%;
    z-index: 1;
  }
}

.mock-form {
  position: relative;
  font-family: Montserrat, sans-serif;
  line-height: 1.428571429;
  z-index: 10;

  .form-title {
    margin: 0;
    padding: 10px 0;
    font-family: inherit;
    font-size: 32px;
    font-weight: 400;
    line-height: inherit;
    color: #fff;
    text-align: center;

    @media (max-width: $sm) {
      font-size: 24px;
      padding: 0 0 10px;
    }
  }

  .mock-form-container {
    padding: 40px 15px;
    background: rgba(255, 255, 255, 0.8);
    color: #000;
    border-radius: 15px;
    font-family: inherit;
    line-height: inherit;

    @media (max-width: $sm) {
      padding: 30px 15px;
    }
  }

  &.loading {
    .mock-form-container {
      opacity: 0.7;
    }
  }

  .b2c-step {
    max-width: 420px;
    margin: 0 auto;
    padding: 0 0 30px;

    .b2c-form-title {
      margin: 0 0 15px;
      padding: 0;
      background: 0 0;
      font-size: 24px;
      color: inherit;
      text-align: center;

      @media (max-width: $sm) {
        font-size: 14px !important;
      }
    }

    .b2c-row {
      .b2c-radio-row {
        margin: 0 0 20px;

        &:last-child {
          margin: 0;
        }

        label {
          position: relative;
          margin: 0;
          padding: 0;
          width: auto;
          border: none !important;
          overflow: visible;
          display: block;
          float: none;

          span {
            position: relative;
            padding: 15px 10px;
            border: none !important;
            border-radius: 5px;
            display: block;
            background: #fbb829;
            background: linear-gradient(to bottom, #fbb829 0, #ee8200 100%);
            color: #333;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            font-weight: normal;
            cursor: pointer;

            @media (max-width: $sm) {
              font-size: 16px;
              padding: 16px 10px;
            }

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 10px;
              width: 16px;
              height: 16px;
              display: block;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAFVBMVEVMaXE0NDQ0NDQ0NDQ0NDQ0NDQ0NDRAjWYDAAAABnRSTlMAEGDPQDB5M8nEAAAAIElEQVQY02NgYGBgYWNiQAGMzHQVYcUrgK6F2nxU7wMAalMAx9mQdeAAAAAASUVORK5CYII=);
              background-size: contain;
              transform: translateY(-50%);
            }
          }
        }

        :focus + span {
          border: none;
        }

        :checked + span {
          background: #ffb165;
        }
      }

      .b2c-radio-wrap.b2c-radio-buttons label input {
        opacity: 0;
        position: absolute;
      }
    }
  }
}

.mock-progress-bar-wrap {
  position: relative;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  padding: 0 48px 30px 0;
  background: 0 0;
  border: none;
  font: inherit;
  border-radius: 5px;
  text-align: center;

  .b2c-progress-text {
    display: block;
    position: absolute;
    top: -2px;
    right: 0;
    left: inherit;
    width: auto;
    margin: 0;
    font-family: inherit;
    text-shadow: none;
    line-height: 13px;

    @media (max-width: $sm) {
      top: -3px;
    }
  }

  .b2c-progress-num {
    color: #000;
    font-size: 13px;
    font-weight: bold;

    @media (max-width: $sm) {
      font-size: 15px;
    }
  }

  .b2c-progress-bar {
    height: 10px;
    margin: 4px 0 0;
    background: #f7f7f7;
    border: none;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: none;

    @media (max-width: $sm) {
      height: 12px;
    }
  }
}
